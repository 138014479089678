<template>
    <div class="Clients" id="ClientsPage">
        <div class="ClientsPanel">
            <header class="ClientsPanel-content">
                <div class="Clients-text">
                    <h1 class="title Clients-title">Extraordinary results require extraordinary approaches.</h1>
                </div>
            </header>
        </div>
        <ClientStoriesNavigation />
        <div class="Trigger TriggerTop" id="ClientsTriggerTop"></div>
        <div class="ClientsPanel">
            <header class="ClientsPanel-content">
                <div class="Clients-text">
                    <h2 class="subtitle Clients-subtitle">Here are some of the companies we are proud to have worked with.</h2>
                </div>
            </header>
        </div>
        <div class="Trigger TriggerBottom" id="ClientsTriggerBottom"></div>
        <div class="Clients-logos" id="Clients-logos">
            <figure
                    :class="`Clients-logo Clients-logo_${client.key}`"
                    :key="index"
                    v-for="(client, index) in ourClients">
                <img :alt="client.name" :src="`img/clients/${client.key}.jpg`" :title="client.name" />
            </figure>
        </div>
    </div>
</template>

<script>
    import { TimelineLite, Power2 } from 'gsap';
    import store from '@/store';

    export default {
        name: 'Clients',
        data() {
            return {
                sceneTimer: null,
                publicPath: process.env.BASE_URL,
                ourClients: store.state.ourClients
            };
        },
        components: {
            ClientStoriesNavigation: () => import('@/components/ClientStoriesNavigation')
        },
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 1000);
            },
            createScene() {
                clearInterval(this.sceneTimer);
                const timeline = new TimelineLite();
                const SMC = new this.$scrollmagic.Controller();

                timeline.staggerFrom('.Clients-logo', .5, {
                    opacity: 0,
                    rotationY: '180deg',
                    rotationZ: '90deg',
                    scale: .7,
                    ease: Power2.easeOut
                }, .08);

                const logosEnter = new this.$scrollmagic.Scene({
                    triggerElement: '#ClientsTriggerTop',
                    triggerHook: .75,
                    //duration: 500,
                    offset: 175,
                    reverse: true
                }).setTween(timeline);

                SMC.addScene(logosEnter);
            }
        }
    };
</script>
